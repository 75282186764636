import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";

const TextContent = tw.div`text-center items-center`;

const Subheading = tw(SubheadingBase)`text-center w-full`;
const Heading = tw(
  SectionHeading
)`w-full leading-tight`;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;

const Statistics = tw.div`flex flex-row text-center md:text-center mt-12 lg:justify-center max-w-screen-lg mx-auto max-w-4xl relative`;
const Statistic = tw.div`sm:inline-block sm:mr-12 last:mr-0 mt-4 lg:w-1/3 max-w-xs`;
const Value = tw.div`font-bold text-lg sm:text-xl lg:text-2xl text-primary-500 tracking-wide`;
const Key = tw.div`font-medium text-center text-secondary-500`;


export default ({
  subheading = "",
  heading = (
    <>
      Smart Contract Security is more important <wbr /> then <span tw="text-primary-500">ever</span>
    </>
  ),
  description = "",
  primaryButtonText = "",
  primaryButtonUrl = "https://timerse.com",
  imageSrc = null,
  imageCss = null,
  imageContainerCss = null,
  imageDecoratorBlob = false,
  imageDecoratorBlobCss = null,
  imageInsideDiv = true,
  statistics = null,
  textOnLeft = false
}) => {
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.
  //Change the statistics variable as you like, add or delete objects
  const defaultStatistics = [
    {
      key: "Lost to hacks in Q1 of 2023",
      value: "$320M+"
    },
    {
      key: "Lost in the largest single hack due to smart contract vulnerabilities",
      value: "$611M+"
    },
    {
      key: "Lost in January 2024 due to hacks on smart contract systems.",
      value: "$126M+"
    }
  ];

  if (!statistics) statistics = defaultStatistics;

  return (
    <Container>
      <ContentWithPaddingXl>
          <TextContent>
            {subheading && <Subheading>{subheading}</Subheading>}
            <Heading>{heading}</Heading>
            <Description>{description}</Description>
            <Statistics>
              {statistics.map((statistic, index) => (
                <Statistic key={index}>
                  <Value>{statistic.value}</Value>
                  <Key>{statistic.key}</Key>
                </Statistic>
              ))}
            </Statistics>
          </TextContent>
        </ContentWithPaddingXl>
    </Container>
  );
};
