import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithBackground.js";
import MainFeature1 from "components/features/TwoColWithButton.js";
import GetStarted from "components/cta/GetStarted";
// import MainFeature2 from "components/features/TwoColSingleFeatureWithStats.js";
// import MainFeature3 from "components/features/TwoColSingleFeatureWithStats2.js";
import Features from "components/features/ThreeColSimple.js";
// import Features from "components/features/ThreeColWithSideImage.js";
import FeatureWithSteps from "components/features/TwoColWithSteps.js";

import SupportIconImage from "images/support-icon.svg";
import ShieldIconImage from "images/shield-icon.svg";
import CustomerLoveIconImage from "images/simple-icon.svg";
import screenshot from "images/hero-screenshot-2.png";
import AdPlacementScreen from "images/AddScreen1.png";


const Subheading = tw.span`uppercase tracking-wider text-sm`;
const HighlightedText = tw.span`text-primary-500`;

export default () => {
  return (
    <AnimationRevealPage disabled>
      <Header buttonRounded={true}/>
      <MainFeature1
        subheading={<Subheading>Advertise on SCAS</Subheading>}
        heading="Increase sales for your smart contract consultancy service"
        description="Advertise on the SCAS App to reach a vast audience of protocol teams, VCs, and analysts."
        buttonRounded={true}
        primaryButtonText="Contact us"
        imageSrc={screenshot}
        primaryButtonUrl={"/contact"}
      />
      <Features
        subheading={<Subheading>Reach</Subheading>}
        heading="We help you reach more customers"
        description="SCAS will showcase you too multiple user groups"
        cards={[
          {
            imageSrc: SupportIconImage,
            title: "Developers",
            description: "SCAS serves as a vital resource for developers seeking in-depth protocol knowledge, enabling them to stay informed and proficient in various protocols"
          },
          {
            imageSrc: ShieldIconImage,
            title: "Protocol Teams",
            description: "Protocol teams are incentivized to maintain optimal security scores within SCAS, fostering a commitment to robust security practices and enhancing overall protocol integrity."
          },
          {
            imageSrc: CustomerLoveIconImage,
            title: "Investors",
            description: "Investors leverage SCAS for rapid risk assessment, to make informed decisions when providing liquidity or investing in tokens."
          },
        ]}
        linkText=""
      />
      <FeatureWithSteps
        subheading={<Subheading>STEPS</Subheading>}
        heading={
          <>
            Easy to <HighlightedText>reach clients</HighlightedText>
          </>
        }
        textOnLeft={false}
        imageSrc={AdPlacementScreen}
        imageDecoratorBlob={true}
        decoratorBlobCss={tw`xl:w-40 xl:h-40 opacity-15 -translate-x-1/2 left-1/2`}
        steps={
          [{
            heading: "Reach Protocols Directly",
            description: "Position your consultancy in front of developers who are actively engaged with our platform, ensuring maximum exposure and impact for your advertisements."
          },
          {
            heading: "Drive Inbound Traffic",
            description: "Capture the attention of potential customers and drive them directly to you, generating valuable inbound traffic and leads."
          },
          {
            heading: "Boost Sales and Conversions",
            description: "Utilize our advertisement space to increase brand awareness, user engagement, and ultimately drive more sales for your services."
          }]
        }
      />
      <GetStarted
        text = "Advertise on SCAS"
        primaryLinkText = "Contact us"
        primaryLinkUrl = "/contact"
      />
      <Footer />
    </AnimationRevealPage>
  );
};
