import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithBackground.js";
import MainFeature1 from "components/features/TwoColWithButton.js";
// import MainFeature2 from "components/features/TwoColSingleFeatureWithStats.js";
// import MainFeature3 from "components/features/TwoColSingleFeatureWithStats2.js";
import Features from "components/features/ThreeColSimple.js";
// import Features from "components/features/ThreeColWithSideImage.js";
import TeamCardGrid from "components/cards/ProfileThreeColGrid.js";

import SupportIconImage from "images/support-icon.svg";
import ShieldIconImage from "images/shield-icon.svg";
import CustomerLoveIconImage from "images/simple-icon.svg";

const Subheading = tw.span`uppercase tracking-wider text-sm`;
export default () => {
  return (
    <AnimationRevealPage disabled>
      <Header buttonRounded={true}/>
      <MainFeature1
        subheading={<Subheading>About SCAS</Subheading>}
        heading="We are SCAS, a goup of hackers and developers"
        buttonRounded={true}
        primaryButtonText="Contact us"
        imageSrc=""
        primaryButtonUrl={"/contact"}
      />
      <Features
        subheading={<Subheading>Our Values</Subheading>}
        heading="We follow these"
        description="SCAS has protected over 400M in user funds by reponsivly disclosing issues."
        cards={[
          {
            imageSrc: SupportIconImage,
            title: "Open Source",
            description: "At SCAS, we highly appreciate open-source applications and aim to release as much as we can over time."
          },
          {
            imageSrc: ShieldIconImage,
            title: "Dedicated Team",
            description: "SCAS's team comprises diverse professionals deeply passionate about cybersecurity."
          },
          {
            imageSrc: CustomerLoveIconImage,
            title: "We hear you",
            description: "SCAS welcomes all constructive feedback and takes every measure to implement it."
          },
        ]}
        linkText=""
      />
      <TeamCardGrid 
        subheading={<Subheading>Our Team</Subheading>}
      />
      <Footer />
    </AnimationRevealPage>
  );
};
