import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/TwoColumnWithInput.js";
import MainFeature from "components/features/TwoColWithButton.js";
import MainFeature3 from "components/features/TwoColSingleFeatureWithStats2";
import MainFeature2 from "components/features/TwoColWithTwoHorizontalFeaturesAndButton.js";
import FeatureWithSteps from "components/features/TwoColWithSteps.js";
import Features from "components/features/ThreeColSimple.js";
//import Testimonial from "components/testimonials/TwoColumnWithImageAndRating.js";
import FAQ from "components/faqs/SingleCol.js";
import GetStarted from "components/cta/GetStarted";
import Footer from "components/footers/FiveColumnWithBackground.js";
import heroScreenshotImageSrc from "images/hero-screenshot-1.png";
import macHeroScreenshotImageSrc from "images/hero-screenshot-2.png";
import decHeroScreenshotImageSrc from "images/hero-screenshot-3.png";
import { ReactComponent as TransparencyIcon } from "feather-icons/dist/icons/codepen.svg";
import { ReactComponent as DecentralizationIcon } from "feather-icons/dist/icons/grid.svg";
import SupportIconImage from "images/support-icon.svg";
import ShieldIconImage from "images/shield-icon.svg";
import CustomerLoveIconImage from "images/simple-icon.svg";

export default () => {
  const Subheading = tw.span`uppercase tracking-widest font-bold text-primary-500`;
  const HighlightedText = tw.span`text-primary-500`;

  return (
    <AnimationRevealPage disabled>
      <Hero roundedHeaderButton={true} />
      <MainFeature3 />
      <MainFeature
        subheading={<Subheading>Why SCAS</Subheading>}
        imageSrc={heroScreenshotImageSrc}
        imageBorder={true}
        imageDecoratorBlob={true}
      />
      <Features
        subheading={<Subheading>Who is SCAS for</Subheading>}
        heading="Use Dapps Securely"
        description="SCAS makes cyber security easy to understand"
        cards={[
          {
            imageSrc: SupportIconImage,
            title: "Developers",
            description: "SCAS serves as a vital resource for developers seeking in-depth protocol knowledge, enabling them to stay informed and proficient in various protocols"
          },
          {
            imageSrc: ShieldIconImage,
            title: "Protocol Teams",
            description: "Protocol teams are incentivized to maintain optimal security score, fostering a commitment to robust security practices and enhancing overall protocol integrity"
          },
          {
            imageSrc: CustomerLoveIconImage,
            title: "Investors",
            description: "Investors leverage SCAS for rapid risk assessment, to make informed decisions when providing liquidity or investing in tokens"
          },
        ]}
        linkText=""
      />
      <FeatureWithSteps
        subheading={<Subheading>STEPS</Subheading>}
        heading={
          <>
            Easy to <HighlightedText>Get Started</HighlightedText>
          </>
        }
        textOnLeft={false}
        imageSrc={macHeroScreenshotImageSrc}
        imageDecoratorBlob={true}
        decoratorBlobCss={tw`xl:w-40 xl:h-40 opacity-15 -translate-x-1/2 left-1/2`}
      />
      <MainFeature2
        subheading={<Subheading>VALUES</Subheading>}
        heading={
          <>
            We Always Abide by Our <HighlightedText>Principles</HighlightedText>
          </>
        }
        imageSrc={decHeroScreenshotImageSrc}
        showDecoratorBlob={false}
        features={[
          {
            Icon: TransparencyIcon,
            title: "Transparency",
            description: "At SCAS, our focus is on openly sharing information.",
            iconContainerCss: tw`bg-green-300 text-green-800`
          },
          {
            Icon: DecentralizationIcon,
            title: "Decentralization",
            description: "SCAS highly values decentralization and strives to support it whenever possible.",
            iconContainerCss: tw`bg-red-300 text-red-800`
          }
        ]}
      />
      {/*<Pricing
        subheading={<Subheading>Pricing</Subheading>}
        heading={
          <>
            Reasonable & Flexible <HighlightedText>Plans.</HighlightedText>
          </>
        }
        plans={[
          {
            name: "Personal",
            price: "$17.99",
            duration: "Monthly",
            mainFeature: "For Individuals",
            features: ["30 Templates", "7 Landing Pages", "12 Internal Pages", "Basic Assistance"]
          },
          {
            name: "Business",
            price: "$37.99",
            duration: "Monthly",
            mainFeature: "For Small Businesses",
            features: ["60 Templates", "15 Landing Pages", "22 Internal Pages", "Priority Assistance"],
            featured: true
          },
          {
            name: "Enterprise",
            price: "$57.99",
            duration: "Monthly",
            mainFeature: "For Large Companies",
            features: ["90 Templates", "27 Landing Pages", "37 Internal Pages", "Personal Assistance"]
          }
        ]}
      /> */}
      {/*Testimonial
        subheading={<Subheading>Testimonials</Subheading>}
        heading={
          <>
            Our Clients <HighlightedText>Love Us.</HighlightedText>
          </>
        }
        testimonials={[
          {
            stars: 5,
            profileImageSrc:
              "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=3.25&w=512&h=512&q=80",
            heading: "Amazing User Experience",
            quote:
              "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco.",
            customerName: "Charlotte Hale",
            customerTitle: "Director, Delos Inc."
          },
          {
            stars: 5,
            profileImageSrc:
              "https://images.unsplash.com/photo-1531427186611-ecfd6d936c79?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.25&w=512&h=512&q=80",
            heading: "Love the Developer Experience and Design Principles !",
            quote:
              "Sinor Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
            customerName: "Adam Cuppy",
            customerTitle: "Founder, EventsNYC"
          }
        ]}
      />*/}
      <FAQ
        subheading={<Subheading>FAQS</Subheading>}
        heading={
          <>
            You have <HighlightedText>Questions ?</HighlightedText>
          </>
        }
        faqs={[
          {
            question: "How is the Security Score Calculated?",
            answer:
              "The protocol score is determined as a value between 0 and 100. You can read more on the docs.scauditstudio.de"
          },
          {
            question: "Where does SCAS get its information from?",
            answer:
              "All information provided by scas is public, we scan blockchains, github repositorys and the web"
          },
          {
            question: "My protocol is not added to SCAS yet",
            answer:
              "Contact us on twitter to to get your protocol listed on scas!"
          },
        ]}
      />
      <GetStarted/>
      <Footer />
    </AnimationRevealPage>
  );
}
